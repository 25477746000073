<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../../../assets/about/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("company.about.banner_title")}}</h2>
      </div>
    </div>
    <section class="info-container">
        <!-- <img class="bg-img" src="../../../../asset/about/awards-bg.png" alt=""> -->
        <div class="text text-title">{{t("company.about.text1")}}</div>
        <div class="text">{{t("company.about.text2")}}</div>
        <div class="text" v-if="false">{{t("company.about.text3")}}</div>
        <div class="text" v-if="false">{{t("company.about.text4")}}</div>
        <div class="info-box">
            <h1 class="info-title">{{ t("company.about.awards_title") }}</h1>
            <div class="info-card">
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards1.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec1") }}</div>
                </div>
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards2.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec2") }}</div>
                </div>
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards3.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec3") }}</div>
                </div>
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards4.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec4") }}</div>
                </div>
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards5.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec5") }}</div>
                </div>
                <div class="card">
                    <img class="card-icon" src="../../../../assets/about/awards6.png" alt="" />
                    <div class="card-text">{{ t("company.about.awards_dec6") }}</div>
                </div>
            </div>
        </div>
    </section>
    <section class="patents-container">
        <div class="content-box">
            <div class="certification">
                <div class="title">{{t("company.about.certification_title")}}</div>
                <div class="sub-item">
                    <img src="../../../../assets/about/awards7.png" alt="">
                    <div>{{t("company.about.certification_dec1")}}</div>
                </div>
                <div class="sub-item">
                    <img src="../../../../assets/about/awards8.png" alt="">
                    <div>{{t("company.about.certification_dec2")}}</div>
                </div>
            </div>
            <div class="patents">
              <div class="title">{{t("company.about.patents_title")}}</div>
              <ul class="list">
                <li>{{t("company.about.patents_item1")}}</li>
                <li>{{t("company.about.patents_item2")}}</li>
                <li>{{t("company.about.patents_item3")}}</li>
                <li>{{t("company.about.patents_item4")}}</li>
                <li>{{t("company.about.patents_item5")}}</li>
                <li>{{t("company.about.patents_item6")}}</li>
                <li>{{t("company.about.patents_item7")}}</li>
                <li>{{t("company.about.patents_item8")}}</li>
                <li>{{t("company.about.patents_item9")}}</li>
                <li>{{t("company.about.patents_item10")}}</li>
                <li>{{t("company.about.patents_item11")}}</li>
                <li>{{t("company.about.patents_item12")}}</li>
              </ul>
            </div>
        </div>
    </section>
    <section class="sec-container">
      <div class="d-title">
        <div>{{t("company.about.org_title1")}}<br>{{t("company.about.org_title2")}}</div>
      </div>
      <div class="d-container">
        <div class="d-img">
          <div class="img">
            <img src="../../../../assets/about/ccpa.png" alt="">
          </div>
          <div class="text">{{t("company.about.org1")}}</div>
        </div>
        <div class="d-img">
          <div class="img">
            <img src="../../../../assets/about/GDPR.png" alt="">
          </div>
          <div class="text">{{t("company.about.org2")}}</div>
        </div>
        <div class="d-img">
          <div class="img">          
            <img src="../../../../assets/about/HIPAA.png" alt="">
          </div>
          <div class="text">{{t("company.about.org3")}}</div>
        </div>
        <div class="d-img">
          <div class="img">
            <img src="../../../../assets/about/LGPD.png" alt="">
          </div>
          <div class="text">{{t("company.about.org4")}}</div>
        </div>
      </div>
    </section>
    <section class="city-list">
      <div class="list">
        <div class="item">
          <img src="../../../../assets/about/city1.png" alt="">
          <div>{{t("company.about.city1")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city2.png" alt="">
          <div>{{t("company.about.city2")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city3.png" alt="">
          <div>{{t("company.about.city3")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city4.png" alt="">
          <div>{{t("company.about.city4")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city5.png" alt="">
          <div>{{t("company.about.city5")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city6.png" alt="">
          <div>{{t("company.about.city6")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city7.png" alt="">
          <div>{{t("company.about.city7")}}</div>
        </div>
        <div class="item">
          <img src="../../../../assets/about/city8.png" alt="">
          <div>{{t("company.about.city8")}}</div>
        </div>
      </div>
    </section>
    <section class="logo-sec">
      <h1 class="logo-title">{{ t("company.about.customer") }}</h1>
      <div class="list-box">
        <div class="img-list" :class="{'active': isActive == 1}">
          <div class="logo">
            <img src="../../../../assets/about/weltmeister.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/maserati.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/mclaren.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/bmw.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/volvo.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/starbucks.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/eleme.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/swarovski.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/rb.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/walmart.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/nu-skin.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/michael-kors.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/mont-blanc.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/mary-kay.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/didi.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/huaxia.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/spring-airlines.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/boc.jpeg" alt="">
          </div>
          <div class="logo">
            <img src="../../../../assets/about/aeroflot.jpeg" alt="">
          </div>
          <div class="logo">
            <img src="../../../../assets/about/finnair.jpeg" alt="">
          </div>
          <div class="logo">
            <img src="../../../../assets/about/banyan-tree.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/hilton.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/fairmont.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/peninsula.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/jumeirah.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/igola.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/standard-chartered.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/pingan.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/huatai.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/dbs.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/shrb.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/zhuoyue.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/ef.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/hujiang.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/wanda.jpeg" alt="">
          </div><div class="logo">
            <img src="../../../../assets/about/juwai.jpeg" alt="">
          </div>
        </div>
      </div>
      <div class="toggle"><span :class="{'active': isActive == 0}" @click="toogle(0)"></span><span :class="{'active': isActive == 1}" @click="toogle(1)"></span></div>
    </section>
    <HelpForm/>
  </div>
</template>
<script>
import {ref} from 'vue'
import { useI18n } from "vue-i18n";
import HelpForm from 'Components/HelpForm/index.vue';
export default {
    name: 'ABOUT',
    components: {HelpForm},
    setup() {
        const { t } = useI18n();
        let isActive = ref(0)
        return {
          t,
          isActive
        };
    },
    methods: {
      toogle(e) {
        this.isActive = e
      }
    }
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 793px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      font-weight: bold;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }

  .info-container {
      background: url(../../../../assets/about/awards-bg.png) no-repeat;
      min-height: 500px;
      padding: 80px 0;
    .text {
        max-width: 1140px;
        text-align: center;
        margin: 0 auto;
        font-size: 18px;
        color: #333333;
        line-height: 40px;
        &.text-title {
            font-size: 30px;
            line-height: 40px;
            padding: 0 100px;
            // margin-top: 80px;
            margin-bottom: 30px;
        }
    }
    .info-box {
        .info-card {
            margin-top: 60px;
            .card {
                background: transparent;
                margin-right: 20px;
                .card-icon {
                    height: 100px;
                    width: auto;
                }
                .card-text {
                    padding: 0;
                    font-size: 14px;
                }
            }
        }
        @media (max-width: 1020px) {
            .info-card {
                flex-wrap: wrap;
                margin: 0 20px;
                .card {
                    flex: none;
                    margin: 0 15px 80px;
                    width: calc((100% / 3) - 30px);
                }
            }
        }

        @media (max-width: 1200px) {
            .info-card {
                margin: 0 20px;
                width: auto;
            }
        }
    }
  }

  .info-box {
    max-width: 1140px;
    margin: 80px auto;
    text-align: center;
    padding: 20px 0;
    .info-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .info-card {
      display: flex;
      flex-wrap: wrap;
      &.info-card-img {
        display: block;
      }
      .card {
        flex: 1;
        width: 20%;
        background: #f4f4f4;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        .card-icon {
          width: 100%;
          margin-bottom: 16px;
        }
        .card-title {
          color: #102b47;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 16px;
          line-height: 20px;
        }
        .card-text {
          padding: 0 20px 20px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 24px;
        }
      }
      .img {
        width: 100%;
      }
    }


    .toggle {
      text-align: center;
    }

    .toggle span {
      margin: 0 -2px;
      opacity: 0.5;
      cursor: pointer;
    }

    .toggle span.active {
      opacity: 1;
    }

    .toggle span:before {
      content: '';
      display: inline-block;
      height: 0;
      width: 30px;
      border-bottom: 10px solid #ff9333;
      border-left: 10px solid transparent;
    }

    .toggle span:after {
      content: '';
      display: inline-block;
      height: 0;
      width: 30px;
      border-top: 10px solid #ff9333;
      border-right: 10px solid transparent;
    }

    .toggle span:first-child:before {
      width: 40px;
      border-left: none;
    }

    .toggle span:last-child:after {
      width: 40px;
      border-right: none;
    }

    .toggle span:first-child:before,
    .toggle span:first-child:after {
      border-top-color: #ff9333;
      border-bottom-color: #ff9333;
    }

    @media all and (max-width: 600px) {
      .toggle span:before {
        width: 20px;
      }

      .toggle span:after {
        width: 20px;
      }

      .toggle span:first-child:before {
        width: 30px;
      }

      .toggle span:last-child:after {
        width: 30px;
      }
    }
  }
  
  .patents-container {
    .content-box {
        display: flex;
        flex-wrap: wrap;
        background: #F7F7F6;
        margin: 0 0 0 e("calc((100% - 1140px) / 2)");
        @media all and (max-width: 1200px) {
          margin: 0;
        }
        .certification {
            width: 350px;
            // height: 350px;
            background: #A19F9D;
            padding: 50px 30px;
            text-align: center;
            color: #ffffff;
            // margin: 0 50px 0 20%;
            .title {
                font-size: 14px;
                line-height: 28px;
            }
            .sub-item {
                float: left;
                width: 50%;
                margin: 30px 0 0;
                font-size: 12px;
                img {
                    width: 100px;
                    margin-bottom: 30px;
                }
            }
        }
        .patents {
          flex: 1;
          // max-width: 900px;
          padding: 30px;
          .title {
            margin-top: 30px;
            margin-bottom: 40px;
          }
          ul {
            li {
              float: left;
              margin: 0 0 20px;
              color: #333333;
              width: 33%;
              font-size: 0.75em;
              line-height: 2.4;
            }
          }
        }
    }
  }

   .sec-container {
    .d-title {
      margin-top: 80px;
      text-align: center;
      font-size: 22px;
      color: #333333;
      font-weight: bold;
      &::after {
        content: "";
        display: block;
        width: 120px;
        text-align: center;
        margin: 20px auto 40px;
        background: #ff9333;
        border: 3px solid #ff9333;
        border-radius: 3px;
      }
    }
    .d-container {
      width: 1140px;
      margin: 50px auto 0;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      &::after {
        content: "";
        display: block;
        clear: both;
      }
      .d-img {
        width: 25%;
        text-align: center;
        float: left;
        .img {
          width: 100%;
          height: 170px;
          position: relative;
          img {
            width: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }

  .city-list {
    padding-top: 100px;
    user-select: none;
    .list {
      width: 1140px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      .item {
        // flex: 1;
        float: left;
        width: 25%;
        text-align: center;
        position: relative;
        padding: 0 15px;
        margin-bottom: 25px;
        img {
          width: 100%;
        }
        div {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 20px;
          color: #ffffff;
        }
      }
    }
    @media (max-width: 1200px) {
      .list {
        width: 100%;
        padding:20px; 
      }
    }
  }
  .logo-sec {
    padding: 100px 0;
    overflow-x: hidden;
    .logo-title {
      text-align: center;
      font-size: 22px;
      line-height: 30px;
      padding: 0 30px;
    }
    .logo-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .list-box {
      position: relative;
      margin: 0 auto 50px;
      width: 1140px;
      .img-list {
        width: 200%;
        transition-duration: 0.5s;
        transform: translateZ(0);
        column-count: 2;
        column-gap: 0;
        &>div:nth-child(-1n+18) {
          opacity: 1;
        }
        &>div:nth-last-child(-1n+18) {
          opacity: 0;
        }
        &.active {
          transform: translateX(-1140px);
          &>div:nth-child(-1n+18) {
            opacity: 0;
          }
          &>div:nth-last-child(-1n+18) {
            opacity: 1;
          }
        }
      }
      .logo {
        width: 16.66%;
        padding-top: 12%;
        display: inline-block;
        position: relative;
        img {
          position: absolute;
          top: 20%;
          left: 20%;
          width: 60%;
          height: 60%;
          object-fit: contain;
          transform: scale(0.7);
        }
      }
    }
    .toggle {
      text-align: center;
    }

    .toggle span {
      margin: 0 -2px;
      opacity: 0.5;
      cursor: pointer;
    }

    .toggle span.active {
      opacity: 1;
    }

    .toggle span:before {
      content: '';
      display: inline-block;
      height: 0;
      width: 30px;
      border-bottom: 10px solid #ff9333;
      border-left: 10px solid transparent;
    }

    .toggle span:after {
      content: '';
      display: inline-block;
      height: 0;
      width: 30px;
      border-top: 10px solid #ff9333;
      border-right: 10px solid transparent;
    }

    .toggle span:first-child:before {
      width: 40px;
      border-left: none;
    }

    .toggle span:last-child:after {
      width: 40px;
      border-right: none;
    }

    .toggle span:first-child:before,
    .toggle span:first-child:after {
      border-top-color: #ff9333;
      border-bottom-color: #ff9333;
    }

    @media all and (max-width: 600px) {
      .toggle span:before {
        width: 20px;
      }

      .toggle span:after {
        width: 20px;
      }

      .toggle span:first-child:before {
        width: 30px;
      }

      .toggle span:last-child:after {
        width: 30px;
      }
    }
  }
}
</style>
